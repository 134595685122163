var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.sites,
                  "items-per-page": 5,
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.controls",
                    fn: function (props) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: { icon: "", color: "green" },
                            on: {
                              click: function ($event) {
                                return _vm.archiveItem(props.item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-archive")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Archived Sites")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }