<template>
  <div>
    <br />
    <div>
        <h1>Archived Sites</h1>
    </div>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="sites"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.controls="props">
                    <v-btn class="mx-2" icon color="green" @click="archiveItem(props.item)">
                    <v-icon>mdi-archive</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-col>   
    </v-row>
  </div>    
</template>

<script>
import Service from '@/services/Service.js';
import Vue from 'vue';

import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      monitorSystem: null,
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      items: ['Solaredge', 'Fronius', 'Solarlog', 'Locus', 'Also', 'Enphase', 'Powerdash', "GMP", "Allearth", "Auroravision", "Chint", "Egauge", "Pika", "Smasunnyportal", "Solectria"],
      headers: [
        { text: 'Site ID', value: 'id' },
        { text: 'Name', value: 'name'},
        { text: "Unarchive", value: "controls", sortable: false }
      ],
      sites: [],
    };
  },
  mounted() {
    document.title = "Admin"
  },
  beforeMount(){
    // var date = new Date();
    // this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
  },
  created() {
    var date = new Date();
    this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
    this.initialize();
  },
  methods: {
    initialize() {
        console.log("initialize...")
        Service.getSitesFutureAll(this.toDateVal)
          .then((response) => {
            const s = response.data
            // filter by isArchived == true
            this.sites = s.filter(site => site.isArchived == true);
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading filter data`, {position: "top-right"})
            console.error(error)
          })
      },   
      archiveItem(item) {
        console.log(item.id)
        let params = {
          isArchived: false
        };
        
        Service.updateSite(item.id, params)
            .then(response => {
              console.log('flag isArchived updated')
              // reload site data
              Service.getSitesFutureAll(this.toDateVal)
                .then((response) => {
                    const s = response.data
                    // filter by isArchived == true
                    this.sites = s.filter(site => site.isArchived == true);
                })
                .catch((error) => {
                    Vue.$toast.error(`Error loading filter data`, {position: "top-right"})
                    console.error(error)
                })
            })
            .catch(error => {
              console.error("Error updating site data:", error.response);
            });
      }      
  },
  computed: {

  },
};
</script>

<style>
</style>